.writingContainer {
    padding: 50px;
    text-align: center;
    max-width: 1000px;
    margin: 0 auto;

}

.article {
    padding: 100px;
    text-align:center;
}

h1 {
    color: #000000;
    font-size: 2.5rem;
}

p {
    color: #000000;
    font-size: 1rem;
    line-height: 1.6;
}

.divider {
  border-top: 1px solid #ccc;
  margin: 20px 0; /* Adjust margin as needed */
}

